import React, { Component } from "react";
import { connect } from "react-redux";

export default LoadStates => {
  class ComposedComponent extends Component {
    render() {
      return <LoadStates {...this.props} />;
    }
  }
  const mapStateToProps = state => {
    return state;
  };

  return connect(mapStateToProps)(ComposedComponent);
};
